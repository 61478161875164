import { useEffect, useMemo, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import iconTableUpload from "../../assets/icons/icon_upload_document.svg";
import Preview from "../../components/Preview";
import Tabs from "../../components/Tabs";
import Tooltip from "../../components/Tooltip";
import {
  DOCUMENT_TYPE_ENUM,
  DOCUMENT_TYPE_UPLOADED,
  TOAST_TYPE,
} from "../../constants";
import { DEVICE_WIDTH } from "../../constants/dimension";
import { FORMULA_GROUPING, FORMULAS_MAP } from "../../constants/formula";
import {
  API_APPLICATIONS,
  API_SAVE_APPLICATION,
  URL_APPLICATION_CREATE,
  URL_APPLICATION_RESULT,
  API_GET_APPLICATION_FILE,
  API_VALIDATE_APPLICATION,
  API_APPLICATION_REPROCESS,
  URL_DASHBOARD,
} from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import debounce from "../../utils/api/debounce";
import calculateFormulaFromData from "../../utils/formula/calculate_formula_from_data";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import generatePeriodString from "../../utils/string/generatePeriodString";
import FinancialSpreadingValidateTable from "../FinancialSpreadingUploadValidate/FinancialSpreadingValidateTable";
import { ReactComponent as ResizeIcon } from "../../assets/icons/icon-resize.svg";
import { API_APPLICATION_STATUS } from "../../constants/statuses";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";
import TextInput from "../../components/TextInput";
import loadOcrIntoHighlight from "../../utils/financialSpreading/loadOcrIntoHighlight";

const INITIAL_CONFIRM_STATE = DOCUMENT_TYPE_UPLOADED.reduce(
  (finalConfirmState, { key }) => {
    const confirmState = {};
    const documentTypeGroups = FORMULA_GROUPING[key];
    documentTypeGroups.forEach(({ summary = [], name }) => {
      const groupConfirmState = confirmState[name] || {};
      summary.forEach(({ displayCode }) => {
        groupConfirmState[displayCode] = false;
      });

      confirmState[name] = groupConfirmState;
    });

    return {
      ...finalConfirmState,
      [key]: confirmState,
    };
  },
  {}
);

export default function FinancialSpreadingUploadValidate() {
  const { application_id } = useParams();
  const tableRef = useRef(null);

  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState({});
  const { setLoading } = useLoader();
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const [pendingSave, setPendingSave] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const fileRef = useRef(null);
  // const [aggregatedTextContents, setAggregatedTextContents] = useState([]);
  // const highlightsRef = useRef([]);
  const [selectedRow, setSelectedRow] = useState();
  const [fileHeight, setFileHeight] = useState();
  const [triggerReRender, setTriggerRender] = useState(0);
  const [confirmState, setConfirmState] = useState(INITIAL_CONFIRM_STATE);
  const [tabsLabel, setTabsLabel] = useState(DOCUMENT_TYPE_UPLOADED);
  const [editingRows, setEditingRows] = useState({});
  const abortControllerRef = useRef(null);
  const authenticatedFetch = useAuthenticatedFetch()
  const [ocrData, setOcrData] = useState({})
  const ocrHighlightsRef = useRef([])
  const [loadedPages, setLoadedPages] = useState({})

  const handleResetConfirm = (documentType, groupName) => {
    setConfirmState((currentConfirmState) => {
      const documentTypeConfirmState = confirmState[documentType] || {};

      return {
        ...currentConfirmState,
        [documentType]: {
          ...documentTypeConfirmState,
          [groupName]: {},
        },
      };
    });
  };

  const handleConfirmStateUpdate = (documentType, groupName, displayCode) => {
    const documentTypeConfirmState = confirmState[documentType] || {};
    const groupConfirmState = documentTypeConfirmState[groupName] || {};
    const updatedConfirmState = {
      ...confirmState,
      [documentType]: {
        ...documentTypeConfirmState,
        [groupName]: {
          ...groupConfirmState,
          [displayCode]: true,
        },
      },
    };

    setConfirmState(updatedConfirmState);

    const allDocumentTypeConfirmed = Object.values(
      updatedConfirmState[documentType]
    ).every((section) => Object.values(section)[0]);

    if (allDocumentTypeConfirmed) {
      setTabsLabel((prev) => {
        const updatedLabel = prev.map((item) => {
          if (item.key === documentType) {
            return {
              ...item,
              isConfirmed: true,
            };
          }
          return item;
        });
        return updatedLabel;
      });
    }
  };

  useEffect(() => {
    const selectedRef = ocrHighlightsRef.current[selectedRow] 
    ocrHighlightsRef.current.forEach((ref) => {
      ref.style.display = "none";
    });

    if (!selectedRef) {
      return;
    }
    selectedRef.style.display = "";
  }, [selectedRow]);

  const fetchApplication = (application_id) => {
    setLoading(true);
    authenticatedFetch(`${API_APPLICATIONS}/${application_id}`)
      .then(
        ({
          data: { data, metadata, status, ocr_results },
        }) => {
          data.sort((a, b) => a.row_number - b.row_number);
          setData(data);
          setMetadata(metadata);

          if (status === API_APPLICATION_STATUS.VALIDATED) {
            // set all confirm state to true if already validated
            setConfirmState(
              DOCUMENT_TYPE_UPLOADED.reduce((finalConfirmState, { key }) => {
                const confirmState = {};
                const documentTypeGroups = FORMULA_GROUPING[key];
                documentTypeGroups.forEach(({ summary = [], name }) => {
                  const groupConfirmState = confirmState[name] || {};
                  summary.forEach(({ displayCode }) => {
                    groupConfirmState[displayCode] = true;
                  });

                  confirmState[name] = groupConfirmState;
                });

                return {
                  ...finalConfirmState,
                  [key]: confirmState,
                };
              }, {})
            );

            setTabsLabel(DOCUMENT_TYPE_UPLOADED.map((item) => ({
              ...item,
              isConfirmed: true,
            })));
          }

          if (ocr_results) {
            const ocrData = Object.keys(ocr_results).reduce((finalOcrData, page_number) => {
              const ocr_result = ocr_results[page_number]
              const { contents = [], widths, heights } = ocr_result

              contents.sort((a, b) => (heights - a.bbox[1]) - (heights - b.bbox[1]));
              const lines = [];
              const defaultNewLine = { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity, constructedStr: '' }
              
              let currentLine = { ...defaultNewLine, rowData: [] };
              let currentY = null;

              contents.forEach((contentRow) => {
                const { bbox } = contentRow
                const [left, reversedBottomY, right, reversedTopY] = bbox;
                const minY = (heights - reversedTopY) ;
                const maxY = (heights - reversedBottomY) ; 
                const midY = (minY + maxY) / 2
        
                if (currentY === null || Math.abs(midY - currentY) > 5) {
                    if (currentLine.rowData.length > 0) {
                        currentLine.rowData.sort((a, b) => a.bbox[0] - b.bbox[0])

                        currentLine.constructedStr = currentLine.rowData.map(({ text }) => text).join('\n')

                        const { maxX, maxY, minX, minY, rowData } = currentLine

                        lines.push(currentLine); 

                        const separatedLine = currentLine.constructedStr.split('\n').map((constructedStr) => ({
                          constructedStr,
                          maxX,
                          maxY, 
                          minX, 
                          minY, 
                          rowData,
                        }))

                        if (separatedLine.length > 1) {
                          lines.push(...separatedLine); 
                        }
                    }
                    currentLine = { ...defaultNewLine, rowData: [] }; 
                    currentY = midY; 
                }
          
                currentLine.rowData.push(contentRow);

                currentLine.minX = Math.min(currentLine.minX, left / widths);
                currentLine.minY = Math.min(currentLine.minY, minY / heights);
                currentLine.maxX = Math.max(currentLine.maxX, right / widths);
                currentLine.maxY = Math.max(currentLine.maxY, maxY / heights);
              });

              currentLine.rowData.sort((a, b) => a.bbox[0] - b.bbox[0])

              currentLine.constructedStr = currentLine.rowData.map(({ text }) => text).join(' ')

              if (currentLine.rowData.length > 0) {
                lines.push(currentLine);
              }

              return {
                ...finalOcrData, 
                [parseInt(page_number, 10) + 1]: lines,
              }
            }, {})

            setOcrData(ocrData)
          }
        }
      )
      .catch((err) => {
        showToast(err.message, TOAST_TYPE.ERROR);
        navigate(URL_DASHBOARD);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchApplication(application_id)
  }, [application_id]);

  useEffect(() => {
    const getApplicationFileApi = API_GET_APPLICATION_FILE.replace(
      ":id",
      application_id
    );
    authenticatedFetch(getApplicationFileApi, {
      responseType: 'blob',
    })
      .then((fileBlob) => {
        const fileType = fileBlob.type;
        const createdFileUrl = URL.createObjectURL(fileBlob);
        setFileUrl(createdFileUrl);
        setFileType(fileType);
      })
      .catch((err) => {
        console.error("error fetching application file:", err);
      });
  }, [application_id]);

  const handleSaveData = async (signal) => {
    const saveApplicationApi = API_SAVE_APPLICATION.replace(
      ":id",
      application_id
    );

    const savedData = data.filter((_, index) => !editingRows[index]);

    authenticatedFetch(saveApplicationApi, {
      data: { data: savedData, new_metadata: metadata },
      signal,
      method: 'PUT',
    })
      .catch((err) => {
        if (!err.message || ["AbortError", "CanceledError"].includes(err.name)) {
          return
        }

        showToast(err.message, TOAST_TYPE.ERROR);
      });
  };
  const debouncedSave = debounce(handleSaveData, 500);

  useEffect(() => {
    if (pendingSave) {
      // debouncedSave();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new abort controller for the new request
      const controller = new AbortController();
      abortControllerRef.current = controller;

      debouncedSave(controller.signal);
      setPendingSave(false);
    }
  }, [pendingSave]);

  const [activeTab, setActiveTab] = useState(
    DOCUMENT_TYPE_ENUM.INCOME_STATEMENT
  );

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (tableRef.current && isScroll) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeTab, isScroll]);

  const { width } = useWindowDimensions();

  const handleScroll = () => {
    if (window?.scrollY > 290) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  // useEffect(() => {
  //   if (!data.length || !aggregatedTextContents.length) {
  //     return;
  //   }

  //   highlightsRef.current.forEach((ref) => ref.remove());
  //   highlightsRef.current = [];
  //   setSelectedRow(null);
  //   let lastMatchedIndex = 0;

  //   data.forEach(({ client_account_name, document_type }, dataIndex) => {
  //     if (document_type !== currentDocumentType) {
  //       return;
  //     }
  //     for (let i = lastMatchedIndex; i < aggregatedTextContents.length; i++) {
  //       const { constructedStr, content } = aggregatedTextContents[i];

  //       const lowerClientAccountName = client_account_name.toLowerCase().trim();
  //       const lowerConstructedStr = constructedStr.toLowerCase().trim();

  //       if (lowerConstructedStr.startsWith(lowerClientAccountName)) {
  //         const { parentRef: firstParentElement } = content[0];
  //         const { parentRef: lastParentElement } = content[content.length - 1];

  //         const { left, height } = firstParentElement.getBoundingClientRect();

  //         const { right } = lastParentElement.getBoundingClientRect();

  //         const parentTransform =
  //           window.getComputedStyle(firstParentElement).transform;

  //         let scaleX = 1;

  //         if (parentTransform !== "none") {
  //           const matrixValues = parentTransform.match(/matrix\(([^)]+)\)/);

  //           if (matrixValues) {
  //             const values = matrixValues[1].split(",");

  //             scaleX = parseFloat(values[0]);
  //           }
  //         }

  //         const highlightDiv = generateHighlightDiv({
  //           width: (right - left) / scaleX + 4,
  //           height,
  //         });

  //         highlightsRef.current[dataIndex] = highlightDiv;
  //         firstParentElement.appendChild(highlightDiv);

  //         lastMatchedIndex = i;
  //         break;
  //       }

  //       // multi line client account name
  //       if (lowerClientAccountName.startsWith(lowerConstructedStr)) {
  //         const { parentRef: firstParentElement } = content[0];
  //         const { parentRef: lastParentElement } = content[content.length - 1];
  //         const firstParentElements = [firstParentElement];
  //         const lastParentElements = [lastParentElement];
  //         let remainingCheckedStr = lowerClientAccountName
  //           .substring(lowerConstructedStr.length)
  //           .trim();
  //         for (let j = i + 1; j < aggregatedTextContents.length; j++) {
  //           const { constructedStr, content } = aggregatedTextContents[j];
  //           const lowerConstructedStr = constructedStr.toLowerCase().trim();

  //           if (
  //             !(
  //               lowerConstructedStr.startsWith(remainingCheckedStr) ||
  //               remainingCheckedStr.startsWith(lowerConstructedStr)
  //             )
  //           ) {
  //             i = j - 1;
  //             break;
  //           }

  //           const { parentRef: firstParentElement } = content[0];
  //           const { parentRef: lastParentElement } =
  //             content[content.length - 1];
  //           firstParentElements.push(firstParentElement);
  //           lastParentElements.push(lastParentElement);

  //           // last string
  //           if (lowerConstructedStr.startsWith(remainingCheckedStr)) {
  //             i = j;
  //             break;
  //           }
  //         }
  //         const { left, top } = firstParentElement.getBoundingClientRect();

  //         const { bottom: bottomFirstParentElementBottom } =
  //           firstParentElements[
  //             firstParentElements.length - 1
  //           ].getBoundingClientRect();
  //         const height = bottomFirstParentElementBottom - top;

  //         const right = lastParentElements.reduce(
  //           (right, currentLastParentElement) => {
  //             const { right: currentRight } =
  //               currentLastParentElement.getBoundingClientRect();
  //             if (currentRight > right) {
  //               return currentRight;
  //             }

  //             return right;
  //           },
  //           0
  //         );

  //         const parentTransform =
  //           window.getComputedStyle(firstParentElement).transform;

  //         let scaleX = 1;

  //         if (parentTransform !== "none") {
  //           const matrixValues = parentTransform.match(/matrix\(([^)]+)\)/);

  //           if (matrixValues) {
  //             const values = matrixValues[1].split(",");

  //             scaleX = parseFloat(values[0]);
  //           }
  //         }

  //         const highlightDiv = generateHighlightDiv({
  //           width: (right - left) / scaleX + 4,
  //           height,
  //         });

  //         highlightsRef.current[dataIndex] = highlightDiv;
  //         firstParentElement.appendChild(highlightDiv);

  //         lastMatchedIndex = i;

  //         break;
  //       }
  //     }
  //   });
  // }, [aggregatedTextContents, data]);

  const handleValidateApplication = () => {
    setLoading(true);
    const validateApplicationApi = API_VALIDATE_APPLICATION.replace(
      ":id",
      application_id
    );

    authenticatedFetch(validateApplicationApi, {
      method: 'PUT',
    })
      .then(() => {
        navigate(`${URL_APPLICATION_RESULT}/${application_id}`);
      })
      .catch((err) => {
        if (!err.message) {
          return
        }
        showToast(err.message, TOAST_TYPE.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderButton = useMemo(() => {
    const isNextButtonDisabled = DOCUMENT_TYPE_UPLOADED.some(({ key }) => {
      const documentTypeGroups = FORMULA_GROUPING[key];
      const documentTypeConfirmState = confirmState[key] || {};

      return documentTypeGroups.some(({ summary = [], name }) => {
        const groupConfirmState = documentTypeConfirmState[name] || {};
        return summary.some(({ displayCode }) => {
          return !groupConfirmState[displayCode];
        });
      });
    });

    return (
      <div className="flex gap-[1.25rem]">
        <button
          onClick={() => navigate(URL_APPLICATION_CREATE)}
          className="w-[4.5rem] !h-[2rem] default border-[1px] border-neutral-medium !py-[0.25rem] !px-[0.5rem] font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] flex justify-center items-center"
        >
          Previous
        </button>
        <button
          onClick={() => handleValidateApplication()}
          disabled={isNextButtonDisabled}
          className="w-[4.5rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem] primary font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] text-white flex justify-center items-center"
        >
          Next
        </button>
      </div>
    );
  }, [confirmState, application_id, navigate]);

  const currentDocumentType = activeTab;
  const documentMetadata = metadata[currentDocumentType] || {};
  const { page_number, fiscal_period_order = [] } = documentMetadata;

  const [currentScrolledPage, setCurrentScrolledPage] = useState(page_number)

  const isCurrentPageLoaded = loadedPages[currentScrolledPage]

  useEffect(() => {
    if (!isCurrentPageLoaded) { 
      return
    }
    const currentPageOcrRowData = ocrData[currentScrolledPage];

    if (!data.length || !currentPageOcrRowData?.length) {
      return;
    }

    ocrHighlightsRef.current.forEach((ref) => ref.remove());
    ocrHighlightsRef.current = [];
    setSelectedRow(null);

    const currentPageDiv = fileRef.current.getPages(currentScrolledPage - 1);

    const parentElement = currentPageDiv.querySelector(
      ".rpv-core__text-layer"
    );

    if (parentElement) {
      loadOcrIntoHighlight(parentElement, currentPageOcrRowData, data, currentDocumentType, ocrHighlightsRef)
      return
    }

    const observer = new MutationObserver(() => {
      const parentElement = currentPageDiv.querySelector(
        ".rpv-core__text-layer"
      );

      if (!parentElement) {
        return
      }
        loadOcrIntoHighlight(parentElement, currentPageOcrRowData, data, currentDocumentType, ocrHighlightsRef)
        observer.disconnect(); // Disconnect once the element is found
      });

    observer.observe(currentPageDiv, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [ocrData, data, currentScrolledPage, currentDocumentType, isCurrentPageLoaded]);

  // const aggregatePdfTextsByLine = (textDivs) => {
  //   const result = [];
  //   let currentArray = [];
  //   let constructedStr = "";

  //   for (let index = 0; index < Array.from(textDivs).length; index++) {
  //     const element = textDivs[index]
  //     const str = element.textContent;
  //     const isNewLine = element.tagName === "BR";
  //     if (isNewLine) {
  //       if (currentArray.length > 0) {
  //         result.push({
  //           constructedStr,
  //           content: currentArray,
  //           width,
  //           height: fileHeight,
  //         });
  //         currentArray = [];
  //         constructedStr = "";
  //       }
  //     } else {
  //       currentArray.push({ parentRef: textDivs[index] });
  //       constructedStr += str;
  //     }
  //   }

  //   setAggregatedTextContents(result);
  // }

  // const handleAggregatePdfTextsByLine = (currentPageIndex) => {
  //   if (!fileRef.current) {
  //     return
  //   }
  //   const currentPageDiv = fileRef.current.getPages(currentPageIndex);

  //   const textDivs = currentPageDiv.querySelectorAll(
  //     ".rpv-core__text-layer-text"
  //   );

  //   if (textDivs?.length) {
  //     aggregatePdfTextsByLine(textDivs);
  //     return;
  //   }

  //   const observer = new MutationObserver(() => {
  //     const textDivs = currentPageDiv.querySelectorAll(
  //       ".rpv-core__text-layer-text"
  //     );

  //     if (!textDivs?.length) {
  //       return;
  //     }
  //     aggregatePdfTextsByLine(textDivs);
  //     observer.disconnect(); // Disconnect once the element is found
  //   });

  //   observer.observe(currentPageDiv, { childList: true, subtree: true });

  //   return () => {
  //     observer.disconnect();
  //   };
  // };

  // useEffect(() => {
  //   handleAggregatePdfTextsByLine(currentScrolledPage - 1)
  // }, [currentScrolledPage])

  const loadDataIntoPdf = (
    textContent,
    [, , width, height],
    currentPageIndex,
  ) => {
    setFileHeight(height);
    setIsFileLoaded(true);
    setCurrentScrolledPage(currentPageIndex + 1)

    setTimeout(() => {
      setLoadedPages(currentLoadedPages => {
        const newLoadedPages = ({
          ...currentLoadedPages,
          [currentPageIndex+1]: true, 
        })

        return newLoadedPages
      })
    }, 100)
  };

  const documentTypeGroups = FORMULA_GROUPING[currentDocumentType];

  useEffect(() => {
    if (triggerReRender && isFileLoaded) {
      setTimeout(() => {
          fileRef.current.scrollToPage?.(currentScrolledPage)
      }, 200);
    }
  }, [triggerReRender, isFileLoaded]);

  useEffect(() => {
    if (page_number && isFileLoaded) {
      fileRef.current.scrollToPage?.(page_number);
    }
  }, [page_number, isFileLoaded]);

  const handleSetEditingRows = (index, value) => {
    setEditingRows((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const addNewDataRow = (index, groupName) => {
    const createTime = Date.now();

    setData((currentData) => {
      const newData = [...currentData];
      const emptyRow = {
        client_account_name: "",
        tp_standard_account_name: "",
        account_code: "",
        document_type: currentDocumentType,
        amount: fiscal_period_order.reduce(
          (acc, period) => ({ ...acc, [period]: 0 }),
          {}
        ),
        add_time: createTime,
        groupName,
      };
      newData.splice(index + 1, 0, emptyRow);

      return newData;
    });

    setEditingRows((prev) => {
      const newEditingRows = Object.keys(prev).reduce(
        (newEditingRows, currentIndex) => {
          const currentEditRow = prev[currentIndex];

          const newIndex =
            currentIndex > index ? parseInt(currentIndex) + 1 : currentIndex;

          return {
            ...newEditingRows,
            [newIndex]: currentEditRow,
          };
        },
        {}
      );

      return {
        ...newEditingRows,
        [index + 1]: true,
      };
    });
  };

  const deleteRow = (index) => {
    setData((currentData) => currentData.filter((_, i) => i !== index));

    setEditingRows((prev) => {
      const newEditingRows = Object.keys(prev).reduce(
        (newEditingRows, currentIndex) => {
          const currentEditRow = prev[currentIndex];

          const newIndex =
            currentIndex > index ? parseInt(currentIndex) - 1 : currentIndex;

          return {
            ...newEditingRows,
            [newIndex]: currentEditRow,
          };
        },
        {}
      );

      return newEditingRows;
    });
    setPendingSave(Date.now());
  };

  const handleSaveRow = (index, newRowData) => {
    setData((currentData) => {
      const newData = [...currentData];

      newData[index] = newRowData;

      return newData;
    });

    handleSetEditingRows(index, undefined);

    setPendingSave(Date.now());
  };

  const onInverseRow = (index) => {
    setData((currentData) => {
      if (!currentData[index]) {
        return currentData;
      }

      const newData = [...currentData];

      const { amount = {} } = newData[index];

      const newAmount = { ...amount };

      for (let periodString in newAmount) {
        const value = newAmount[periodString] || 0;

        newAmount[periodString] = value * -1;
      }

      newData[index] = {
        ...newData[index],
        amount: newAmount,
      };

      return newData;
    });

    setPendingSave(Date.now());
  };

  const handleSave = () => {
    setPendingSave(Date.now());
  };

  const { getStandardAccountOptions } = useApplicationContext();

  const standardAccountOptions = (
    getStandardAccountOptions(currentDocumentType) || []
  )?.map(({ account_code, tp_standard_account_name, groupName }) => ({
    value: account_code,
    label: tp_standard_account_name,
    groupName,
  }));

  const handleAmountKeyChange = (
    documentType,
    previousFiscalPeriod,
    newFiscalPeriod
  ) => {
    if (previousFiscalPeriod === newFiscalPeriod) {
      return;
    }

    let newFiscalPeriodExists = false;

    setData((currentData) => {
      const newData = [];
      for (let i = 0; i < currentData.length; i++) {
        const datum = currentData[i] || {};
        const { amount, document_type } = datum;

        if (document_type !== documentType) {
          newData.push(datum);
          continue;
        }

        if (newFiscalPeriod in amount) {
          newFiscalPeriodExists = true;
          break;
        }

        const newAmount = { ...amount };

        if (previousFiscalPeriod in newAmount) {
          newAmount[newFiscalPeriod] = newAmount[previousFiscalPeriod];
          delete newAmount[previousFiscalPeriod]; // Remove the old key
        }

        datum.amount = newAmount;
        newData.push(datum);
      }

      if (newFiscalPeriodExists) {
        return currentData;
      }

      return newData;
    });

    if (newFiscalPeriodExists) {
      showToast("Period already exists", TOAST_TYPE.ERROR);
      return;
    }

    setMetadata((currentMetadata) => {
      const { [documentType]: currentDocumentTypeMetadata = {} } =
        currentMetadata;
      const { fiscal_period_order = [] } = currentDocumentTypeMetadata;
      const newFiscalPeriodOrder = [...fiscal_period_order];

      for (let i = 0; i < fiscal_period_order.length; i++) {
        const currentFiscalPeriod = fiscal_period_order[i];
        if (currentFiscalPeriod === previousFiscalPeriod) {
          newFiscalPeriodOrder[i] = newFiscalPeriod;
          break;
        }
      }

      return {
        ...currentMetadata,
        [documentType]: {
          ...currentDocumentTypeMetadata,
          fiscal_period_order: newFiscalPeriodOrder,
        },
      };
    });

    setPendingSave(Date.now());
  };

  const onDateChange = (documentType, previousFiscalPeriod, month, year) => {
    const { period, audited } = extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(month, year, period, audited);

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const onPeriodChange = (documentType, previousFiscalPeriod, period) => {
    const { monthInt, year, audited } =
      extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(
      monthInt,
      year,
      period,
      audited
    );

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const onAuditedChange = (documentType, previousFiscalPeriod, audited) => {
    const { monthInt, year, period } =
      extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(
      monthInt,
      year,
      period,
      audited
    );

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const calculatedUploadedDocuments = useMemo(() => {
    const calculatedDocumentTypesArr = DOCUMENT_TYPE_UPLOADED.filter(
      ({ key }) => metadata[key]
    );
    const calculatedFormulas = calculatedDocumentTypesArr.reduce(
      (fin, { key, formula_type = key }) => {
        const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
          ...data,
          document_type: formula_type,
        }));
        return [...fin, ...currFormula];
      },
      []
    );

    const baseCalculateMetadata = calculatedDocumentTypesArr.reduce(
      (existingMetadata, { key, formula_type = key }) => {
        return {
          ...existingMetadata,
          [formula_type]: metadata[key],
        };
      },
      {
        isOldestYear: false,

        // TODO: replace this value's usage to use extract from period string instead
        numberOfMonths: 12,
      }
    );

    const result = calculateFormulaFromData(
      data,
      calculatedFormulas,
      baseCalculateMetadata
    );

    result.sort((a, b) =>
      a.account_code.localeCompare(b.account_code, undefined, { numeric: true })
    );
    return result;
  }, [data, metadata]);

  const onBack = () => navigate(URL_APPLICATION_CREATE);

  // width for client account name / tp standard account name
  const namesWidth = width >= DEVICE_WIDTH.DESKTOP ? 200 : 120;
  const nonNamesWidth = 100;
  const minWidth =
    namesWidth * 2 + (fiscal_period_order.length + 1) * nonNamesWidth;

  const handleSubmitReprocessApplication = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const pageNumbers = formData.get("page_numbers");

    const data = {
      page_numbers: pageNumbers,
      document_type: currentDocumentType,
    };

    const applicationReprocessApi = API_APPLICATION_REPROCESS.replace(
      ":id",
      application_id
    );
    setLoading(true);
    authenticatedFetch(applicationReprocessApi, {
      data,
      method: "POST",
    }).then(({ data: { data, metadata, status } }) => {
      data.sort((a, b) => a.row_number - b.row_number);
      setData(data);
      setMetadata(metadata);
      setOpenUploadModal(false);
    }).catch(err => { 
      if (!err.message) { 
        return
      }
      showToast('Error when reprocessing document. Please try again later', TOAST_TYPE.ERROR)
    }).finally(() => {
      setLoading(false)
    });
  };

  return (
    <div
      className={`w-full max-w-default sm:px-[48px] 2xl:px-[64px] relative min-h-[calc(100vh-70px)] flex flex-col`}
    >
      <div className="py-[2.5rem] flex-col gap-[1rem]">
        <div className="pb-[1rem] flex justify-between">
          <div className="flex gap-[1rem] flex-1 items-center">
            <button onClick={onBack}>
              <img
                src={backIcon}
                alt="back-button"
                className="cursor-pointer"
              />
            </button>
            <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
              Financial Spreading Confirmation
            </span>
          </div>
          {!isScroll && renderButton}
        </div>
        <ul className="list-disc pl-[1.625rem] text-[1rem] leading-[1.75rem] tracking-[-0.54px] font-[500] w-full">
          <li>
            The Income Statement, Balance Sheet and Cash Flow Statement are
            split into three tabs. Compare the original financial statements on
            the left to the mapped accounts and figures on the right.
          </li>
          <li>
            Verify the accuracy of the mapped accounts, figures and fiscal
            periods; or click on them to make corrections. Please disregard rows
            that are subtotals or totals.
          </li>
          <li>
            Every section has a calculated total that requires your
            confirmation. After confirming the calculated totals on all
            available tabs, click "Next" to proceed.
          </li>
        </ul>
      </div>

      <div className="flex flex-col items-center gap-[2rem] w-full">
        <div className="max-w-[1792px] w-full flex flex-col gap-[1.5rem]">
          <div
            className={`flex justify-between align-center ${
              isScroll
                ? "sticky w-full bg-[#fff] top-0 z-[20] py-[0.75rem] px-[0.5rem] pb-0 mb-[24px]"
                : ""
            }`}
          >
            <Tabs
              tabsLabel={tabsLabel}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              wrapperClassname={
                isScroll ? "py-[0.75rem] px-[0.5rem] !border-none" : ""
              }
            />
            {isScroll && renderButton}
          </div>
          <PanelGroup direction="horizontal" className={`gap-[2px] !overflow-visible h-full`}>
            <Panel
              defaultSizePercentage={40}
              maxSize={50}
              className="flex flex-col gap-[1.5rem] min-w-[430px] sticky top-0 self-start"
            >
              <div className="flex gap-[1.5rem] items-center">
                <span className="text-[1.125rem] font-[700] leading-[2rem] tracking-[-0.72px]">
                  Original Document
                </span>
                <div onClick={() => setOpenUploadModal(true)}>
                  <Tooltip
                    tooltip="Re-upload Document"
                    topTooltip
                    tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px] !right-[-24px] top-[36px] !my-0 !h-[20px]"
                  >
                    <img
                      src={iconTableUpload}
                      className="p-[8px] cursor-pointer"
                    />
                  </Tooltip>
                </div>
              </div>
              <Preview
                url={fileUrl}
                type={fileType}
                onPageLoad={loadDataIntoPdf}
                ref={fileRef}
                initialPage={page_number}
                containerClassName="bg-white p-[1.5rem] !pr-0 border-[1px] border-solid rounded-[20px] flex flex-col w-full aspect-[4/3]"
                key={triggerReRender}
              />
            </Panel>
            <PanelResizeHandle
              className="relative w-[24px] h-[24px] flex items-center justify-center"
              onDragging={(isDragging) => {
                if (!isDragging) {
                  setTriggerRender((prev) => prev + 1);
                }
              }}
              style={{
                top: (fileHeight ?? 700 - 50) / 2,
              }}
            >
              <ResizeIcon color="black" />
            </PanelResizeHandle>
            <Panel
              defaultSizePercentage={50}
              minSize={50}
              className="flex flex-col overflow-x-auto hideScrollbar"
              style={{
                width: minWidth,
                // overflow: "auto",
              }}
            >
              <div ref={tableRef} />
              <div
                className={`flex items-center mb-[1.5rem]  text-[1.125rem] font-[700] leading-[2rem] tracking-[-0.72px] h-[36px]`}
              >
                Financial Mapping
              </div>
              <div
                className={`flex-col gap-[1.5rem] flex overflow-auto`}
                style={{
                  overflow: "auto",
                }}
              >
                {documentTypeGroups.map(
                  ({ name, summary, content, canInverseValue }, index) => {
                    const groupConfirmState =
                      confirmState[currentDocumentType][name];

                    const isLastTable = index === documentTypeGroups.length - 1;

                    return (
                      <FinancialSpreadingValidateTable
                        minWidth={minWidth}
                        name={name}
                        fiscalPeriods={fiscal_period_order}
                        data={data}
                        documentType={currentDocumentType}
                        standardAccountOptions={standardAccountOptions}
                        onAddRow={addNewDataRow}
                        onDeleteRow={deleteRow}
                        onSaveRow={handleSaveRow}
                        canChangeDate={index === 0}
                        onDateChange={onDateChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onPeriodChange={onPeriodChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onAuditedChange={onAuditedChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onSave={handleSave}
                        summary={summary}
                        content={content}
                        calculatedData={calculatedUploadedDocuments}
                        onInvertClick={canInverseValue && onInverseRow}
                        onRowHover={setSelectedRow}
                        confirmState={groupConfirmState}
                        onConfirm={handleConfirmStateUpdate}
                        onResetConfirm={handleResetConfirm}
                        showMonth={[
                          DOCUMENT_TYPE_ENUM.INCOME_STATEMENT,
                          DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
                        ].includes(activeTab)}
                        editingRows={editingRows}
                        setEditingRows={handleSetEditingRows}
                        isLastTable={isLastTable}
                        showEmptyAccountCode={isLastTable}
                      />
                    );
                  }
                )}
              </div>
            </Panel>
          </PanelGroup>
        </div>
      </div>
      {openUploadModal && (
        <div className="fixed inset-0 w-full h-full bg-white bg-opacity-50 backdrop-blur-md flex flex-col justify-center items-center z-50 text-[#121212] text-xl gap-[2.5rem]">
          <div className="mt-[32px] flex flex-col gap-[0.5rem] p-[2rem] bg-white rounded-[20px] w-full max-w-[1186px]">
            <form onSubmit={handleSubmitReprocessApplication}>
              Reprocess Document
              <TextInput
                id="page_numbers"
                label={`Enter the page numbers for ${
                  DOCUMENT_TYPE_UPLOADED.find(
                    ({ key, formula_type = key }) =>
                      formula_type === currentDocumentType
                  )?.text
                } Document (e.g. 1,2,3-5)`}
                required
              />
              <button
                className={`mt-2 flex h-[48px] w-full flex-col justify-center items-center rounded-[8px] text-[#fff] text-base font-semibold leading-[1.5rem] bg-gradient-to-r from-[#023972] to-[#356CA5]`}
                type="submit"
              >
                Next
              </button>
              <button
                className="mt-2 flex h-[48px] w-full flex-col justify-center items-center rounded-[8px] text-[#121212] text-base font-semibold leading-[1.5rem]"
                onClick={() => setOpenUploadModal(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="min-h-[2rem]"></div>
    </div>
);
}
