import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ArrowDropdownIcon } from "../../assets/icons/arrow_drop_down.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/icon_info.svg";

export default function Dropdowns({
  data = [],
  label,
  className = "",
  inputClassName = "",
  placeholder = "",
  defaultValue = "",
  labelClassName = "",
  onChange,
  allowCustomText = false,
  disabled = false,
  required = false,
  isSubmit = false,
  popoverClassname = "",
  popoverDataClassname = "",
  dropdownDataClassname = "",
  readOnly = false,
  showOptionsList = true,
  hideNotFound,
  value,
  ...props
}) {
  const { id, name = id } = props;

  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1); // Track the focused option
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const itemRefs = useRef([]); // Array of refs for each dropdown item

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleChangeFilter = (value) => {
    setKeyword(value);
    setOpen(true);
    setFocusedIndex(-1); // Reset focused index when the input changes
  };

  // hideAsOption is used to hide the item from the dropdown list and only show it as a preloaded value
  const filteredData = useMemo(() => data.filter((item) =>
    item.label.toLowerCase().includes(keyword.toLowerCase())
  ), [keyword, data]) 

  useEffect(() => {
    itemRefs.current = []
  }, [filteredData])

  const debouncedFilter = debounce(handleChangeFilter, 300);

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setFocusedIndex((prev) => {
        const nextIndex = prev < filteredData.length - 1 ? prev + 1 : prev;
        itemRefs.current[nextIndex]?.scrollIntoView({ block: "nearest" });
        return nextIndex;
      });
    } else if (e.key === "ArrowUp") {
      setFocusedIndex((prev) => {
        const prevIndex = prev > 0 ? prev - 1 : prev;
        itemRefs.current[prevIndex]?.scrollIntoView({ block: "nearest" });
        return prevIndex;
      });
    } else if (e.key === "Enter" && focusedIndex >= 0) {
      const selectedItem = filteredData[focusedIndex];
      if (selectedItem) {
        onChange?.(selectedItem.value, name);
        inputRef.current.value = selectedItem.label;
        setOpen(false);
      }
    }
  };

  let shownDefaultValue = defaultValue;

  if (!allowCustomText && typeof defaultValue !== "undefined" && defaultValue !== null) {
    const defaultData = filteredData.find(
      ({ value }) => value.toString() === defaultValue.toString()
    );
    shownDefaultValue = defaultData?.label;
  }

  useEffect(() => {
    if (inputRef.current && shownDefaultValue) {
      inputRef.current.value = shownDefaultValue
    }
  }, [shownDefaultValue])

  const shownFilteredData = filteredData.filter(({ hideAsOption }) => !hideAsOption);

  return (
    <div
      className={`relative inline-block text-left ${className} w-full`}
      ref={dropdownRef}
    >
      <label htmlFor="menu-button" className={`${labelClassName} ${disabled && 'text-[#D1D1D1]'}`}>
        {label}
      </label>
      <div
        className={`relative flex ${!disabled && 'cursor-pointer'}`}
        onClick={() => !disabled && setOpen((prev) => !prev)}
      >
        <input
          name={name}
          ref={inputRef}
          id="menu-button"
          defaultValue={shownDefaultValue}
          autoComplete="off"
          aria-expanded={open}
          aria-haspopup="true"
          placeholder={placeholder}
          onChange={(e) => {
            e.stopPropagation();
            if (allowCustomText) {
              onChange?.(e.target.value, name);
            }
            setOpen(true);
            handleChangeFilter(e.target.value);
            debouncedFilter(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          required={required}
          className={`text-[#121212] font-[600] text-[0.875rem] leading-[1.5rem] tracking-[-0.32px] placeholder:font-[400] ${inputClassName} ${
            required && isSubmit && !props?.value
              ? "!border-b-[#F65737] !border-b-[1.5px]"
              : ""
          }`}
          readOnly={readOnly}
        />
        <span
          className={`absolute right-[0.75rem] top-[50%] translate-y-[-50%] ${
            open ? "rotate-180" : ""
          }`}
        >
          <ArrowDropdownIcon color="#121212" />
        </span>
      </div>
      {isSubmit && required && !props?.value && (
        <div className="flex items-center gap-[4px] flex-grow flex-shrink-0 basis-0 mt-[4px] absolute">
          <InfoIcon color="#F65737" />
          <span className="text-[#F65737] text-[0.75rem] leading-[1rem]">
            Please fill up this field
          </span>
        </div>
      )}
      {open && (!(hideNotFound && shownFilteredData.length === 0)) && showOptionsList && (
        <div
          className={`absolute w-full z-50 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${popoverClassname}`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div
            className={`p-[0.5rem] max-h-[200px] overflow-y-auto ${popoverDataClassname}`}
            role="none"
          >
            {shownFilteredData.length === 0 && (
              <span
                className="block px-[0.75rem] py-[0.5rem] text-[0.875rem] font-[500] leading-[1.25rem] tracking-[-0.28px]"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
              >
                Not found
              </span>
            )}
            {shownFilteredData.length > 0 &&
              shownFilteredData.map((item, index) => (
                <span
                  ref={(el) => (itemRefs.current[index] = el)} // Assign each item to the refs array
                  className={`block px-[0.75rem] py-[0.5rem] text-[0.875rem] font-[500] leading-[1.25rem] tracking-[-0.28px] hover:bg-neutral-flat-hover cursor-pointer ${dropdownDataClassname} ${
                    index === focusedIndex ? "bg-gray-200" : ""
                  }`}
                  role="menuitem"
                  tabIndex="-1"
                  id={`menu-item-${index}`}
                  key={item.value}
                  onMouseEnter={() => setFocusedIndex(index)}
                  onClick={() => {
                    onChange?.(item.value, name);
                    inputRef.current.value = item.label;
                    setOpen(false);
                  }}
                >
                  {item.label}
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
