import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import saveSuccessImg from "../../assets/images/save_success.png";
import Dropdowns from "../../components/Dropdowns";
import FinancialReportTableView from "../../components/FinancialReport/FinancialReportTableView";
import Modal from "../../components/Modal";
import Tabs from "../../components/Tabs";
import TextInput from "../../components/TextInput";
import {
  ALL_DOCUMENT_TYPES,
  DOCUMENT_TYPE_ENUM,
  DOCUMENT_TYPE_PROPERTY_GENERATED,
  TOAST_TYPE,
  UNIT_OPTIONS,
  UNIT_TYPES,
} from "../../constants";
import { PERFORMANCE_CLASSIFICATION_OPTIONS, SORT_TYPES } from "../../constants/options";
import {
  API_APPLICATIONS,
  API_CONFIRM_APPLICATION,
  URL_APPLICATION_VALIDATE,
  URL_DASHBOARD,
} from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useLoader } from "../../context/Loader";
import { usePendingApplications } from "../../context/PendingApplications";
import { useToaster } from "../../context/Toaster";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import downloadBlob from "../../utils/common/downloadBlob";
import generateWorkbook from "../../utils/financialSpreading/generateWorkbook";
import aggregateData from "../../utils/formula/aggregateData";
import calculateGeneratedDocuments from "../../utils/formula/calculateGeneratedDocuments";
import calculateUploadedDocuments from "../../utils/formula/calculateUploadedDocuments";
import sortPeriodString from "../../utils/formula/sortPeriodString";
import { financialSpreadingResultColumns, generatedColumns } from "./columns";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";
import TextDatePicker from "../../components/TextDatePicker";

const FIELDS = {
  group_name: "group_name",
  company_name: "company_name",
  doc_currency: "doc_currency",
  out_currency: "out_currency",
  exchange_rate: "exchange_rate",
  unit: "unit",
  sort_by: "sort_by",
  performance: "performance",
  next_review_date: 'next_review_date',
};

export default function FinancialSpreadingResult() {
  const navigate = useNavigate();
  const { setLoading, isLoading } = useLoader();
  const { application_id } = useParams();
  const [applicationData, setApplicationData] = useState({});
  const [hiddenPeriods, setHiddenPeriods] = useState([]);
  const { removePendingApplication } = usePendingApplications();
  const { width } = useWindowDimensions();
  const authenticatedFetch = useAuthenticatedFetch()

  const { showToast } = useToaster();
  const [
    formData,
    setFormData,
  ] = useState({});

  const {
    group_name,
    company_name,
    doc_currency,
    out_currency,
    unit,
    exchange_rate,
    sort_by,
    performance,
    next_review_date,
  } = formData

  const [decimalPlace, setDecimalPlace] = useState(2);

  const increaseDecimalPlace = () =>
    setDecimalPlace((decimalPlace) => decimalPlace + 1);
  const decreaseDecimalPlace = () =>
    setDecimalPlace((decimalPlace) => Math.max(decimalPlace - 1, 0));

  const { currencyOptions } = useApplicationContext();

  const setHiddenPeriodByIndex = (index, checked) => {
    setHiddenPeriods((currentHiddenPeriods) => {
      const newHiddenPeriods = [...currentHiddenPeriods];

      // hidden means not checked
      newHiddenPeriods[index] = !checked;

      return newHiddenPeriods;
    });
  };

  const fetchApplication = () => {
    setLoading(true);
    authenticatedFetch(`${API_APPLICATIONS}/${application_id}`)
      .then(({ data: applicationData }) => {
        const today = new Date()
        const defaultNextReviewDate = new Date(today.getFullYear() + 1, today.getMonth(), 1).toISOString()

        const {
          data = [],
          group_name,
          company_name,
          doc_currency,
          out_currency,
          unit,
          exchange_rate,
          performance, 
          next_review_date = defaultNextReviewDate,
        } = applicationData;
        data.sort((a, b) => a.row_number - b.row_number);
        const aggregatedData = Object.values(aggregateData(data));

        applicationData.data = aggregatedData;

        setApplicationData(applicationData);

        let formUnit = unit
        if (unit === UNIT_TYPES.None) {
          formUnit = ""
        }

        setFormData({
          group_name,
          company_name,
          doc_currency,
          out_currency,
          unit: formUnit,
          exchange_rate: parseFloat(exchange_rate).toFixed(2),
          sort_by: SORT_TYPES.DESC,
          performance,
          next_review_date: next_review_date || defaultNextReviewDate,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchApplication();
  }, [application_id]);

  const { data = [], metadata = {} } = applicationData;

  const [activeTab, setActiveTab] = useState(
    DOCUMENT_TYPE_ENUM.INCOME_STATEMENT
  );

  const [showSaveModal, setShowSaveModal] = useState(false);

  const toggleShowSaveModal = () => {
    setShowSaveModal((prev) => !prev);
  };

  const currentDocumentType = activeTab;
  const currentDocumentAttribute = ALL_DOCUMENT_TYPES.find(
    ({ key, formula_type = key }) => formula_type === currentDocumentType
  );

  let documentMetadata = metadata[currentDocumentType] || {};

  // generated data doesn't have metadata, so we use income statement or cash flow statement as default
  if (currentDocumentAttribute.type === DOCUMENT_TYPE_PROPERTY_GENERATED) {
    documentMetadata =
      metadata[DOCUMENT_TYPE_ENUM.INCOME_STATEMENT] ||
      metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];
  }
  const { fiscal_period_order = [] } = documentMetadata;

  const sortedFiscalPeriodOrder = sortPeriodString(
    fiscal_period_order,
    sort_by
  );

  const displayedFiscalPeriodOrder = 
    sortedFiscalPeriodOrder.filter((_, index) => {
      return !hiddenPeriods[index];
    })

  const calculatedUploadedDocuments = calculateUploadedDocuments(
    metadata,
    data
  );

  const calculateGeneratedDocumentsFormula = () => {
    const uploadedDocumentsData = [...data, ...calculatedUploadedDocuments];
    const generatedDocumentsData = calculateGeneratedDocuments(
      uploadedDocumentsData,
      1 / exchange_rate
    );

    const allData = [...uploadedDocumentsData, ...generatedDocumentsData];

    allData.sort((a, b) =>
      a.account_code.localeCompare(b.account_code, undefined, {
        numeric: true,
      })
    );
    return allData;
  };

  const combinedDocuments = calculateGeneratedDocumentsFormula();

  const resultTable = financialSpreadingResultColumns(
    displayedFiscalPeriodOrder,
    width,
    [
      DOCUMENT_TYPE_ENUM.INCOME_STATEMENT,
      DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
    ].includes(activeTab)
  );

  const generatedTable = generatedColumns(displayedFiscalPeriodOrder, width);

  const shownData = combinedDocuments.filter(
    ({ document_type }) => document_type === currentDocumentType
  );

  const handleConfirmApplication = async () => {
    setLoading(true);
    const confirmApplicationApi = API_CONFIRM_APPLICATION.replace(
      ":id",
      application_id
    );

    return authenticatedFetch(confirmApplicationApi, {
      data: { group_name, company_name, doc_currency, out_currency, unit, exchange_rate, sort_by, next_review_date, performance },
      method: 'PUT',
    })
      .then((data) => {
        toggleShowSaveModal();
        removePendingApplication(application_id);
        return data;
      })
      .catch((err) => {
        showToast(err.message, TOAST_TYPE.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownloadFile = () => {
    handleConfirmApplication().then(async (res) => {
      // no res means error when confirm application
      if (!res) {
        return;
      }
      const {
        data: { data, metadata, exchange_rate },
      } = res;
      const aggregatedData = Object.values(aggregateData(data));
      const calculatedUploadedDocuments = calculateUploadedDocuments(
        metadata,
        aggregatedData
      );

      const combinedUploadedData = [
        ...aggregatedData,
        ...calculatedUploadedDocuments,
      ];
      const calculatedGeneratedDocuments = calculateGeneratedDocuments(
        combinedUploadedData,
        1 / exchange_rate
      );

      const combinedDocuments = [
        ...combinedUploadedData,
        ...calculatedGeneratedDocuments,
      ];
      combinedDocuments.sort((a, b) =>
        a.account_code.localeCompare(b.account_code, undefined, {
          numeric: true,
        })
      );

      const workbook = generateWorkbook({
        group_name,
        company_name,
        doc_currency,
        out_currency,
        exchange_rate,
        unit,
        data: combinedDocuments,
        decimalPlace,
        fiscalPeriodOrderMap: ALL_DOCUMENT_TYPES.reduce(
          (currentFiscalPeriodOrderMap, currentDocumentAttribute) => {
            const { key, formula_type = key, type } = currentDocumentAttribute;

            let documentMetadata = metadata[formula_type] || {};
            if (type === DOCUMENT_TYPE_PROPERTY_GENERATED) {
              documentMetadata =
                metadata[DOCUMENT_TYPE_ENUM.INCOME_STATEMENT] ||
                metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];
            }

            const { fiscal_period_order = [] } = documentMetadata;

            const displayedFiscalPeriodOrder = sortPeriodString(
              fiscal_period_order.filter((_, index) => {
                return !hiddenPeriods[index];
              }),
              sort_by
            );

            return {
              ...currentFiscalPeriodOrderMap,
              [formula_type]: displayedFiscalPeriodOrder,
            };
          },
          {}
        ),
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      downloadBlob(
        blob,
        `${group_name}_${company_name}_${application_id}.xlsx`
      );
    });
  };

  const handleChange = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
  };

  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const handleDropdownChange = (value, name) => {
    handleChange(name, value);
  };

    const handleCurrencyChange = (value, name) => {
      handleChange(name, value);
      handleChange(FIELDS.exchange_rate, "");
    };

  const isSameCurrency =
    doc_currency && out_currency && doc_currency === out_currency;

  useEffect(() => {
    if (isSameCurrency) {
      handleChange(FIELDS.exchange_rate, 1.0);
    }
  }, [isSameCurrency]);

  if (isLoading) {
    return null;
  }

  const isFormInvalid = Object.values(FIELDS).some((key) => !formData[key])

  return (
    <div
      className={`w-full max-w-default sm:px-[48px] 2xl:px-[64px] relative min-h-[calc(100vh-70px)] flex flex-col`}
    >
      <div className="py-[2.5rem] flex-col gap-[1rem]">
        <div className="pb-[1rem] flex justify-between">
          <div className="flex gap-[1rem] flex-1 items-center">
            <img
              src={backIcon}
              alt="back-button"
              className="cursor-pointer"
              onClick={() =>
                navigate(`${URL_APPLICATION_VALIDATE}/${application_id}`)
              }
            />
            <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
              Financial Spreading Result
            </span>
          </div>
          <div className="flex gap-[1rem]">
            <button
              onClick={() =>
                navigate(`${URL_APPLICATION_VALIDATE}/${application_id}`)
              }
              className="w-[4.5rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem] default border-[1px] border-neutral-medium font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] flex justify-center items-center"
            >
              Previous
            </button>
            <button
              onClick={() => handleConfirmApplication()}
              className="w-[4.5rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem]  primary text-white border-[1px] font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] flex justify-center items-center"
              disabled={isFormInvalid}
            >
              Save
            </button>
            <button
              onClick={() => {
                handleDownloadFile();
              }}
              className="w-[8rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem] primary font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] text-white p-[0.75rem] flex justify-center items-center"
              disabled={isFormInvalid}
            >
              Save & Download
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-[2rem] w-full">
        <div className="flex flex-col p-[2rem] gap-[1.5rem] bg-white max-w-[1186px] w-full rounded-[20px]">
          <div className="flex gap-[1.5rem] w-full">
            <TextInput
              id={FIELDS.group_name}
              label="Group Name"
              className="flex-1"
              value={group_name}
              onChange={handleInputChange}
            />
            <TextInput
              id={FIELDS.company_name}
              label="Company Name"
              className="flex-1"
              value={company_name}
              onChange={handleInputChange}
            />
            <Dropdowns
              id={FIELDS.performance}
              label="Performance Classification​"
              className="flex-1"
              value={performance}
              defaultValue={performance}
              data={PERFORMANCE_CLASSIFICATION_OPTIONS}
              onChange={handleDropdownChange}
            />
            <TextDatePicker
                label="Next Review Date​"
                className="flex-1"
                onChange={handleDropdownChange}
                id={FIELDS.next_review_date}
                value={formData[FIELDS.next_review_date]}
                minDate={new Date()}
                dateFormat="MMM YYYY"
              />
          </div>
          <div className="flex gap-[1.5rem] w-full">
            <Dropdowns
              id={FIELDS.doc_currency}
              label="Document Currency"
              className="flex-1"
              defaultValue={doc_currency}
              data={currencyOptions}
              onChange={handleCurrencyChange}
            />
            <Dropdowns
              id={FIELDS.out_currency}
              label="Output Currency"
              className="flex-1"
              defaultValue={out_currency}
              data={currencyOptions}
              onChange={handleCurrencyChange}
            />
            <TextInput
              id={FIELDS.exchange_rate}
              label="Forex Rate"
              className="flex-1"
              placeholder={
                doc_currency && out_currency
                  ? `1 ${out_currency} to ${doc_currency}`
                  : ""
              }
              defaultValue={parseFloat(exchange_rate).toFixed(2)}
              value={exchange_rate}
              onChange={handleInputChange}
              // disabled={isSameCurrency || !doc_currency || !out_currency}
            />
            <Dropdowns
              id={FIELDS.unit}
              label="Unit"
              className="flex-1"
              defaultValue={unit}
              onChange={handleDropdownChange}
              data={UNIT_OPTIONS}
            />
          </div>
        </div>
        <div className="max-w-[1186px] w-full flex flex-col gap-[1.5rem]">
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabsLabel={ALL_DOCUMENT_TYPES}
          />
          <FinancialReportTableView
            data={shownData}
            currentDocumentAttribute={currentDocumentAttribute}
            client={applicationData}
            formData={{ unit, sort_by, exchange_rate, out_currency }}
            handleDropdownChange={handleDropdownChange}
            setHiddenPeriods={setHiddenPeriods}
            fiscal_period_order={fiscal_period_order}
            setHiddenPeriodByIndex={setHiddenPeriodByIndex}
            hiddenPeriods={hiddenPeriods}
            resultTable={resultTable}
            currentDocumentType={currentDocumentType}
            generatedTable={generatedTable}
            increaseDecimalPlace={increaseDecimalPlace}
            decreaseDecimalPlace={decreaseDecimalPlace}
            decimalPlace={decimalPlace}
          />
        </div>
      </div>
      {/* success modal */}
      {showSaveModal && (
        <Modal
          title={"Saved Successfully!"}
          img={saveSuccessImg}
          confirmText="Back to Home"
          onConfirm={() => navigate(URL_DASHBOARD)}
          onClose={toggleShowSaveModal}
        />
      )}
      {/* failed modal */}
      {/* {showSaveModal && <Modal
        title={"Saved failed!"}
        img={saveFailedImg}
        confirmText="Try a gain"
        titleClassname="!text-[#831919]"
        onClose={toggleShowSaveModal}
        onConfirm={toggleShowSaveModal}
      />} */}
    </div>
  );
}
