import PropTypes from "prop-types";
import { useState } from "react";
import failedImg from "../../assets/images/save-falied.png";
import successImg from "../../assets/images/save_success.png";
import BaseButton from "../../components/BaseButton";
import Upload from "../../components/Upload";
import { FILE_TYPES_IMAGE } from "../../constants/file";
import ToastMessage from "../ToastMessage";
import PreviewAvatar from "./PreviewAvatar";

MemberModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

function MemberModal({ closeModal, isEdit }) {
  const [avatar, setAvatar] = useState(null);
  const [showToastMessage, setShowToastMessage] = useState({
    isSuccessful: false,
    isFailed: false,
  });
  const [value, setValue] = useState({
    name: "",
    avatar: null,
    email: "",
  });

  const handleChangeValue = (event) => {
    setValue((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSave = () => {
    if (value.name && value.email) {
      setShowToastMessage({
        isSuccessful: true,
        isFailed: false,
      });
    } else {
      setShowToastMessage({
        isSuccessful: false,
        isFailed: true,
      });
    }
    closeModal();
  };
  return (
    <div className="fixed inset-0 w-full h-full bg-white bg-opacity-50 backdrop-blur-md flex flex-col justify-center items-center z-50 text-[rgb(32,58,164)] text-xl gap-[2.5rem]">
      <div className="min-w-[582px] p-[1.5rem] flex flex-col gap-[36px] rounded-[17.481px] bg-white bg-opacity-60 shadow-[0px_4px_84px_0px_rgba(0,14,28,0.2)] backdrop-blur-[10px]">
        <div className="flex justify-start gap-[1.5rem]">
          <span className="text-[#121212] text-[1.5rem] font-bold leading-[2rem] tracking-[-0.72px]">
            {!isEdit ? "Create New Member" : "Edit Existing Member"}
          </span>
        </div>
        <div className="flex flex-col gap-[1.5rem]">
          <div className="flex flex-col gap-[4px]">
            <label className="text-[#121212] text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.28px]">
              Name
            </label>
            <input
              placeholder="Enter member name"
              name="name"
              required
              onChange={handleChangeValue}
              className="flex justify-center gap-[0.5rem] bg-[rgba(0, 0, 0, 0.06)] placeholder-[#737373] text-base font-semibold leading-[1.5rem] tracking-[-0.32px] text-[#121212]"
            />
          </div>
          <div className="flex flex-col gap-[4px] justify-start">
            <label className="text-[#121212] text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.28px]">
              Avatar
            </label>
            <Upload
              onFileSelect={files => setAvatar(files[0])}
              files={[avatar]}
              accept={[...FILE_TYPES_IMAGE]}
              showPreview={false}
              containerClassname="!flex !flex-row !gap-[0.5rem] items-center"
              uploadIconClassname="!p-0 !mx-0 w-[24px] h-[24px]"
            />

            {avatar && (
              <div className="bg-white rounded-[20px] w-full max-w-[582px]">
                <PreviewAvatar file={avatar} />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[4px]">
            <label className="text-[#121212] text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.28px]">
              Email
            </label>
            <input
              type="email"
              name="email"
              required
              onChange={handleChangeValue}
              placeholder="Enter your email"
              className="flex justify-center gap-[0.5rem] bg-[rgba(0, 0, 0, 0.06)] placeholder-[#737373] text-base font-semibold leading-[1.5rem] tracking-[-0.32px] text-[#121212]"
            />
          </div>
          <div className="flex flex-col gap-[0.75rem]">
            <BaseButton title={"Save"} handleClick={handleSave} />
            <BaseButton
              title={"Cancel"}
              btnClassname="!bg-[#fff] default"
              titleClassname="!text-[#121212]"
              handleClick={closeModal}
            />
          </div>
        </div>
      </div>
      {showToastMessage.isSuccessful && (
        <ToastMessage
          isOpen={showToastMessage.isSuccessful}
          title="Create Successful!"
          bgContainer="linear-gradient(91deg, #D5F6D4 -1.03%, #EDFCEC 100%)"
          iconUrl={successImg}
          description={
            <div className="text-[#20481F] text-[0.875rem] leading-[1.25rem] tracking-[-0.28px]">
              You have created new member.
            </div>
          }
        />
      )}
      {showToastMessage.isFailed && (
        <ToastMessage
          isOpen={showToastMessage.isFailed}
          title="Create Failed!"
          bgContainer="linear-gradient(91deg, #FFD7CF -1.03%, #FFF8F6 100%)"
          iconUrl={failedImg}
          description={
            <div className="text-[#832B19] text-[0.875rem] leading-[1.25rem] tracking-[-0.28px]">
              Some thing went wrong, please try again.
            </div>
          }
        />
      )}
    </div>
  );
}
export default MemberModal;
