import pdfIcon from '../assets/icons/icon_pdf.svg'
import imageIcon from '../assets/icons/icon_image.svg'
import excelIcon from '../assets/icons/icon_excel.svg'
import docIcon from '../assets/icons/icon_doc.svg'

export const FILE_TYPE_PNG = 'image/png'
export const FILE_TYPE_JPG = 'image/jpeg'
export const FILE_TYPE_JPEG = 'image/jpeg'
export const FILE_TYPE_BMP = 'image/bmp'
export const FILE_TYPE_WEBP = 'image/webp'

export const FILE_TYPE_PDF = 'application/pdf'
export const FILE_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const FILE_TYPE_XLS = 'application/vnd.ms-excel'
export const FILE_TYPE_DOC = 'application/msword'
export const FILE_TYPE_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

export const FILE_TYPE_GROUP_EXCEL = 'excel'
export const FILE_TYPES_EXCEL = [
  FILE_TYPE_XLSX,
  FILE_TYPE_XLS,
]
export const FILE_TYPE_GROUP_WORD = 'word'
export const FILE_TYPES_WORD = [
  FILE_TYPE_DOC,
  FILE_TYPE_DOCX,
]
export const FILE_TYPE_GROUP_IMAGE = 'image'
export const FILE_TYPES_IMAGE = [
  FILE_TYPE_PNG,
  FILE_TYPE_JPG,
  FILE_TYPE_JPEG,
  FILE_TYPE_BMP,
  FILE_TYPE_WEBP,
]

export const FILE_TYPE_TEXT_MAP = {
  [FILE_TYPE_GROUP_IMAGE]: 'Image (e.g. JPG, PNG)',
  [FILE_TYPE_PDF]: 'PDF',
  [FILE_TYPE_GROUP_EXCEL]: 'Excel (e.g. .xlsx, xls)',
  [FILE_TYPE_GROUP_WORD]: 'Word (e.g. .docx, .doc)',
}

export const FILE_TYPE_ICON_MAP = {
  [FILE_TYPE_PDF]: pdfIcon,
  [FILE_TYPE_JPG]: imageIcon,
  [FILE_TYPE_JPEG]: imageIcon,
  [FILE_TYPE_PNG]: imageIcon,
  [FILE_TYPE_BMP]: imageIcon,
  [FILE_TYPE_WEBP]: imageIcon,
  [FILE_TYPE_XLSX]: excelIcon,
  [FILE_TYPE_XLS]: excelIcon,
  [FILE_TYPE_DOC]: docIcon,
  [FILE_TYPE_DOCX]: docIcon,
}

export const FILE_TYPE_MAP = {
  [FILE_TYPE_PDF]: FILE_TYPE_PDF,
  [FILE_TYPE_XLSX]: FILE_TYPE_GROUP_EXCEL,
  [FILE_TYPE_XLS]: FILE_TYPE_GROUP_EXCEL,
  [FILE_TYPE_DOC]: FILE_TYPE_GROUP_WORD,
  [FILE_TYPE_DOCX]: FILE_TYPE_GROUP_WORD,
  [FILE_TYPE_BMP]: FILE_TYPE_GROUP_IMAGE,
  [FILE_TYPE_JPG]: FILE_TYPE_GROUP_IMAGE,
  [FILE_TYPE_JPEG]: FILE_TYPE_GROUP_IMAGE,
  [FILE_TYPE_PNG]: FILE_TYPE_GROUP_IMAGE,
  [FILE_TYPE_WEBP]: FILE_TYPE_GROUP_IMAGE,
}
