import { UNIT_TYPE_NUMBERS_MAP, UNIT_TYPES } from "../../constants"

export default function calculateUnitModifier(oldUnit, newUnit) {
  const newMappedUnit = UNIT_TYPE_NUMBERS_MAP[newUnit] || 1
  let oldMappedUnit = newMappedUnit

  if (oldUnit !== UNIT_TYPES.None) {
    oldMappedUnit = UNIT_TYPE_NUMBERS_MAP[oldUnit] || 1
  }

  return oldMappedUnit / newMappedUnit
}