import { useEffect, useState } from "react";
import ImagePreview from "../ImagePreview";
import PdfPreview from "../PdfPreview";
import checkFileType from "../../utils/file/checkFileType";
import { FILE_TYPE_GROUP_EXCEL, FILE_TYPE_GROUP_IMAGE, FILE_TYPE_GROUP_WORD, FILE_TYPE_PDF } from "../../constants/file";
import withForwardedRef from "../../hoc/withForwardedRef";

const Preview = withForwardedRef(({ file, url, type, forwardedRef, containerClassName, ...otherProps }) => {
  const [fileUrl, setFileUrl] = useState(url)
  useEffect(() => {
    if (file && !url) {
      setFileUrl(URL.createObjectURL(file))
    }
  }, [file])

  useEffect(() => {
    if (url) {
      setFileUrl(url)
    }
  }, [url])

  if (!fileUrl) {
    return null
  }

  const fileType = checkFileType(file?.type || type)

  if (fileType === FILE_TYPE_GROUP_IMAGE) {
    return <div className={containerClassName}><ImagePreview {...otherProps} url={fileUrl} file={file} ref={forwardedRef} /></div>
  }

  if (fileType === FILE_TYPE_PDF) {
    return <div className={containerClassName}><PdfPreview {...otherProps} fileUrl={fileUrl} ref={forwardedRef} /></div>
  }

  if (fileType === FILE_TYPE_GROUP_EXCEL) {
    return null
  }

  if (fileType === FILE_TYPE_GROUP_WORD) {
    return null
  }

  return <div className={containerClassName}><div>Unsupported file type</div></div>

})

export default Preview